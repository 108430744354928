
export const links  = [
	{
		id:1,
		text:"Accueil",
		url:"#home",
	},
	{
		id:3,
		text:"NOS SERVICES",
		url:"#works",
	},
	{
		id:4,
		text:"ANNUAIRE DES PRO",
		url:"/annuaire-des-pro",
	},
	{
		id:2,
		text:"Ressources",
		// url:"#about",
	},
	{
		id:5,
		text:"Me faire appeler",
		url:"#contact",
	}
]

export const services = [
	{
		id: 1,
		title: "Back office CEE externalisé",
		text: "Gagnez en temps et en qualité dans le montage de vos dossiers avec nos équipes spécialisées",
		button: "Parlons-en!"
	},
	{
		id: 2,
		title: "Devis gratuit",
		text: "Recevez la meilleure offre de nos partenaires : Artisans, Bureaux d’études, Fournisseurs…Demander un",
		button: "Parlons-en!"
	},
	{
		id: 3,
		title: "Cession des CEE",
		text: "Obtenez le meilleur prix de rachat de vos certificats d’économie d’énergie",
		button: "Parlons-en!"
	},
	{
		id: 4,
		title: "Financement",
		text: "Bénéficiez d’une étude d’éligibilité de votre projet aux aides et subventions publiques",
		button: "Parlons-en!"
	},
	{
		id: 5,
		title: "Formation",
		text: "Améliorez vos connaissances sur les enjeux de la rénovation énergétique",
		button: "Parlons-en!"
	},
	{
		id: 6,
		title: "Pré-étude",
		text: "Evaluez les gisements d\"économie d\"énergie envisageables et validez les conditions de faisabilité",
		button: "Parlons-en!"
	},
]